<template>
  <div class="a25Config-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button type="primary" @click="handleAddClick">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      :data="a25ConfigList"
      header-cell-class-name="bg-info text-light"
      :height="tableHeight"
      border
      >
      <el-table-column width="50" header-align="center" align="center" label="序号" type="index"></el-table-column>
      <el-table-column prop="configName" header-align="center" align="center" label="配置项名称"></el-table-column>
      <el-table-column prop="configCode" header-align="center" align="center" label="配置项代码"></el-table-column>
      <el-table-column prop="sortId" width="50" header-align="center" align="center" label="排序"></el-table-column>
      <el-table-column header-align="center" width="90" align="center" label="操作">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="light" content="编辑" :open-delay="500" placement="top">
            <i @click="handleEditClick(scope.row)" class="text-info far fa-edit mr-2 operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <my-dialog ref="formDialog"
      :title="formDialogTitle"
      size="md"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleOkClick">
      <a25-config-form
        ref="a25ConfigForm"
        :a25ConfigModel="a25ConfigModel">
      </a25-config-form>
    </my-dialog>

  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import A25ConfigForm from '@/views/A25ConfigManage/A25ConfigForm'
import A25ConfigModel from '@/model/A25ConfigModel'
import a25ConfigService from '@/services/a25ConfigService'
import PageOperation from '@/mixins/PageOperation'
import GetTender from '@/mixins/GetTender'

export default {
  name: 'A25ConfigIndex',
  mixins: [
    GetTender,
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar,
    A25ConfigForm
  },
  data () {
    return {
      a25ConfigList: [],
      a25ConfigModel: new A25ConfigModel(),
      formDialogTitle: '',
      searchModel: {
        companyGuid: '',
        projectGuid: '',
        tenderGuid: ''
      }
    }
  },
  watch: {
    companyGuid (newVal) {
      this.$set(this.searchModel, 'projectGuid', '')
      this.$set(this.searchModel, 'tenderGuid', '')
      this.projects = []
      this.tenders = []
      newVal && this._getProjects(newVal)
    },
    projectGuid (newVal) {
      this.$set(this.searchModel, 'tenderGuid', '')
      this.tenders = []
      newVal && this._getTenders(newVal)
    }
  },
  computed: {
    companyGuid () {
      return this.searchModel.companyGuid
    },
    projectGuid () {
      return this.searchModel.projectGuid
    }
  },
  methods: {
    handleSearchClick () {
      this._getA25ConfigList()
    },
    handleAddClick () {
      this.formDialogTitle = '添加配置信息'
      this.a25ConfigModel = new A25ConfigModel()
      this.$refs.formDialog.open()
    },
    handleEditClick (row) {
      this.formDialogTitle = '编辑配置信息'
      this.a25ConfigModel = new A25ConfigModel(row)
      this.$refs.formDialog.open()
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _a25ConfigModel = new A25ConfigModel(row)
          a25ConfigService.delete(_a25ConfigModel.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getA25ConfigList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.a25ConfigForm.validate()(valid => {
        if (valid) {
          if (!this.a25ConfigModel.resourceId) {
            this._save()
          } else {
            this._edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    _getA25ConfigList () {
      a25ConfigService.list({}, this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.a25ConfigList = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _save () {
      a25ConfigService.add(this.a25ConfigModel)
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getA25ConfigList()
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _edit () {
      a25ConfigService.edit(this.a25ConfigModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getA25ConfigList()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getA25ConfigList()
  }
}
</script>
