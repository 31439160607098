<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="a25ConfigModel"
    label-width="110px"
    >
    <el-form-item label="配置项名称：" prop="configName">
      <el-input
        type="text"
        v-model="a25ConfigModel.configName">
      </el-input>
    </el-form-item>
    <el-form-item label="配置项代码：" prop="configCode">
      <el-input
        type="text"
        v-model="a25ConfigModel.configCode">
      </el-input>
    </el-form-item>
    <el-form-item label="配置项ID：" prop="configID">
      <el-input-number
        v-model.number="a25ConfigModel.configID"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="父节点ID：" prop="parentID">
      <el-input-number
        v-model.number="a25ConfigModel.parentID"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="类型：" prop="configType">
      <el-input-number
        v-model="a25ConfigModel.configType"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="A25显示：" prop="a25IsDisplay">
      <el-switch
        v-model.number="a25ConfigModel.a25IsDisplay"
        active-color="#13ce66"
        inactive-color="#ff4949"
        :active-value="1"
        :inactive-value="0"
        active-text="显示"
        inactive-text="隐藏">
      </el-switch>
    </el-form-item>
    <el-form-item label="F02显示：" prop="f02IsDisplay">
      <el-switch
        v-model.number="a25ConfigModel.f02IsDisplay"
        active-color="#13ce66"
        inactive-color="#ff4949"
        :active-value="1"
        :inactive-value="0"
        active-text="显示"
        inactive-text="隐藏">
      </el-switch>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="a25ConfigModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import A25ConfigModel from '@/model/A25ConfigModel'
import GetTender from '@/mixins/GetTender'

export default {
  name: 'A25ConfigForm',
  mixins: [
    GetTender
  ],
  props: {
    a25ConfigModel: {
      type: A25ConfigModel,
      default () {
        return new A25ConfigModel()
      }
    }
  },
  watch: {
    companyGuid (newVal) {
      this.projectGuid = ''
      this.a25ConfigModel.tenderGuid = ''
      this.projects = []
      this.tenders = []
      newVal && this._getProjects(newVal)
    },
    projectGuid (newVal) {
      this.a25ConfigModel.tenderGuid = ''
      this.tenders = []
      newVal && this._getTenders(newVal)
    }
  },
  data () {
    return {
      companyGuid: '',
      projectGuid: '',
      rules: {
        configName: [
          {
            required: true,
            message: '请输入配置项名称',
            trigger: 'blur'
          }
        ],
        configCode: [
          {
            required: true,
            message: '请输入配置项代码',
            trigger: 'blur'
          }
        ],
        configType: [
          {
            required: true,
            message: '请输入类型',
            trigger: 'blur'
          }
        ],
        parentID: [
          {
            required: true,
            message: '请输入父节点',
            trigger: 'blur'
          }
        ],
        tenderGuid: [
          {
            required: true,
            message: '请选择所属标段',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    }
  },
  created () {
    console.log(this.a25ConfigModel)
  }
}
</script>
