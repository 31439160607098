import BaseService from '@/services/BaseService'

let resourceName = 'a25Config'

class A25ConfigService extends BaseService {
}

const a25ConfigService = new A25ConfigService(resourceName)

export default a25ConfigService
